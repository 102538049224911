import React, {useContext, useState} from "react"
import {Project} from "./projects"
import projects from "./projects.json"
import parse from "html-react-parser"
import {Status} from "./status"
import Button from "./button"
import {EOCode} from "./organisation"
import {TimeContext} from "./App"

export interface Session {
  session: string
  eo: EOCode
  volunteer: string
  description: string
  short: string
  defaultImage?: string
  funnyImage?: string
  link: string
  start: Date
  end: Date
}

function msToText(time: number){
  const sec = Math.floor(time/1000)
  if(sec < 60){
    return `${sec} Sekunden`
  }

  const min = Math.floor(sec / 60)
  if(min < 60){
    return `${min} Minuten`
  }

  const h = Math.floor(min / 60)
  if(h < 60){
    return `${h} Stunden`
  }

  return `${Math.floor(h/24)} Tage`
}

export function Session({
                          session,
                          status,
                          setZoomLink,
                          timeSlot,
                          start, end,
                        }: {
  session: Session
  status: Status
  setZoomLink: (link: string) => void
  start: Date
  end: Date
  timeSlot: string
}) {

  const localeTime = useContext(TimeContext)


  if (!session) {
    return (
        <div
            className="track relative rounded-lg overflow-hidden bg-white shadow-md m-2 border-1 border-green-700"
            style={{gridRow: timeSlot}}
        >
          Fehlende Info
        </div>
    )
  }
  return (
      <div
          className={"track relative rounded-lg overflow-hidden bg-white shadow-md m-2 border-1 border-green-700 "}
          style={{gridRow: timeSlot}}
      >
        <div className={"rounded-t-lg overflow-hidden " + (status === "closed" ? "opacity-40 filter grayscale" : "")}>
          <div className="relative pb-3/4 text-6xl ">
            <img
                className="absolute h-full w-full object-cover"
                src={
                    session.funnyImage || session.defaultImage || "img/missing.jpeg"
                }
                alt="Lustiges Bild"
            />
            <img
                className="absolute h-full w-full object-cover transition-opacity duration-500 ease-in-out hover:opacity-0"
                src={session.defaultImage || "img/missing.jpeg"}
                alt="Bild zum Vortrag"
            />
            <div className="absolute right-0 mr-2 bottom-0 -mb-8 flex -space-x-4 rtl:space-x-reverse">
              {projects
                  .filter((p) => p.eo === session.eo)
                  .map((p) => p.country)
                  .filter((e, idx, arr) => arr.indexOf(e) === idx)
                  .map((c) => (
                      <img
                          key={c}
                          className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800 hover:z-50"
                          src={`img/flags/${c}.svg`}
                          alt=""
                      />
                  ))}
            </div>
          </div>
          <div className="p-2 font-bold text-l text-blue-800 mr-10 mt-2 leading-none">
            {`${start.getHours().toString().padStart(2, "0")}:${start.getMinutes().toString().padStart(2, "0")} – ${end.getHours().toString().padStart(2, "0")}:${end.getMinutes().toString().padStart(2, "0")}`}
          </div>
          <div className="font-bold text-l text-blue-800 mr-10 mt-2 leading-none">
            {projects
                .filter((p) => p.eo === session.eo)
                .map((p) => (
                    <Project id={p.id} key={p.id} className="border-t p-2"/>
                ))}
          </div>
          <div>
            <Button
                enabled={status === "open"}
                onClick={() => setZoomLink(session.link)}
            >
              Teilnehmen
            </Button>
          </div>
        </div>

        {status == "closed" &&
            <div
                className="absolute right-0 top-5 bg-red-500 py-2 pl-5 pr-2 text-left rounded-l-lg text-gray-100 font-bold"> bereits
                beendet
            </div>
        }

        {status == "future" &&
            <div className="absolute right-0 top-5 bg-blue-400 py-2 pl-5 pr-2 text-left rounded-l-lg text-white font-bold">
                  beginnt in {msToText(Math.floor(start.getTime() - localeTime))}
            </div>
        }

        {status == "open" &&
            <div
                className="absolute right-0 top-5 bg-green-600 py-2 pl-5 pr-2 text-left rounded-l-lg text-gray-200 font-bold"> jetzt eintreten!
            </div>
        }


        <hr className="clear-both"/>

        <div className={"m-4 clear-both " + (status === "closed" ? "opacity-30" : "")}>
          <div className="clear-both prose lg:prose-xl text-justify">
            <div className="text text-justify mb-6">
              {parse(
                  session.description?.trim() ||
                  "Weitere Informationen werden später noch hinzugefügt.",
              )}
            </div>
            <div
                className="absolute bottom-0 w-full h-12"
                style={{
                  backgroundImage: "linear-gradient(to bottom, #fff0, white)",
                }}
            />
          </div>
        </div>
      </div>
  )
}
