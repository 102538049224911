import { Session } from "../session"
import { Editor, EditorState } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import React, { memo, useCallback, useEffect, useState } from "react"
import { stateFromHTML } from "draft-js-import-html"
import projects from "../projects.json"
import PictureTab from "./image-crop/picture-tab"
import getCroppedImg from "./image-crop/image_crop"
import { Area, Point } from "react-easy-crop"
import program from "../program.json"

interface Props {
  setEdit: (edit: boolean) => void
  session: ExtendedSession
  reloadSessions: () => void
}

export interface CropInfo {
  image: string
  crop: Point
  pixelCrop: Area
  zoom: number
  rotation: number
}

export interface ExtendedSession extends Session {
  defaultCropInfo?: CropInfo
  funnyCropInfo?: CropInfo
}

function SessionEditor(props: Props) {
  const [saving, setSaving] = useState(false)
  const [session, setSession] = useState<ExtendedSession>(props.session)
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(session.description ?? "")),
  )

  const eoProjects = projects.filter((p) => p.eo === session.eo)

  useEffect(() => {
    getCroppedImg({ ...session.funnyCropInfo! }).then((image) =>
      setSession((session) => ({ ...session, funnyImage: image! })),
    )
  }, [session.funnyCropInfo])

  useEffect(() => {
    getCroppedImg(session.defaultCropInfo!).then((image) =>
      setSession((session) => ({ ...session, defaultImage: image! })),
    )
  }, [session.defaultCropInfo])

  const start = program.find(p => p.organisation === session.eo)!.start
  const end = program.find(p => p.organisation === session.eo)!.end

  const setDefaultCropInfo = useCallback(
    (cropInfo: CropInfo) => {
      console.log("callback", cropInfo)
      setSession({
        ...session,
        defaultCropInfo: cropInfo,
      })
    },
    [setSession, session],
  )

  const setFunnyCropInfo = useCallback(
    (cropInfo: CropInfo) =>
      setSession({
        ...session,
        funnyCropInfo: cropInfo,
      }),
    [setSession, session],
  )
  async function save() {
    setSaving(true)
    const { id } = window.location.search.match(/edit=(?<id>[a-zA-Z0-9]{20})/)!
      .groups as { id: string }

    const sendSession = { ...session }

    // save funny photo
    if (sendSession.funnyCropInfo?.image.startsWith("data:image")) {
      const imageFunnnyResult = await fetch(`/edit/funnyImage/${id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          image: sendSession.funnyCropInfo.image.split(",")[1],
        }),
      })
      const funnyUrl = (await imageFunnnyResult.json()).url
      console.log("funnyUrl:", funnyUrl)
      sendSession.funnyCropInfo.image = funnyUrl
    }

    // save default photo
    if (sendSession.defaultCropInfo?.image.startsWith("data:image")) {
      const imageResult = await fetch(`/edit/defaultImage/${id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          image: sendSession.defaultCropInfo.image.split(",")[1],
        }),
      })
      const defaultUrl = (await imageResult.json()).url
      console.log("defaultUrl:", defaultUrl)
      sendSession.defaultCropInfo.image = defaultUrl
    }

    // no crop-info if there is no image selected
    if (sendSession.defaultCropInfo?.image === "/img/missing.jpeg") {
      delete sendSession.defaultCropInfo
    }
    if (sendSession.funnyCropInfo?.image === "/img/missing.jpeg") {
      delete sendSession.funnyCropInfo
    }

    await fetch(`/edit/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...sendSession,
        defaultImage: undefined,
        funnyImage: undefined,
      }),
    })

    props.reloadSessions()
    props.setEdit(false)
  }

  return (
    <div className="overflow-scroll fixed z-10 bg-black left-0 right-0 top-0 bottom-0 bg-opacity-75 ">
      {!saving && (
        <div className="m-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 bg-indigo-100 bg-opacity-75 border rounded-lg">
          <div className="p-2 row-start-2 md:row-start-1">
            <Session
              timeSlot=""
              session={session}
              status="open"
              setZoomLink={() => ""}
              start={new Date(start)}
              end={new Date(end)}
            />
          </div>
          <div className="xl:col-span-3 lg:col-span-2 col-span-1 bg-gray-100 p-5 rounded-lg m-4 flex flex-col gap-x-8">
            <div className="grow">
              <PictureTab
                defaultCropInfo={session.defaultCropInfo}
                setDefaultCropInfo={setDefaultCropInfo}
                funnyCropInfo={session.funnyCropInfo}
                setFunnyCropInfo={setFunnyCropInfo}
              />
            </div>
            <div className="grid grid-cols-1">
              <span className="pt-3 pb-1 block text-gray-600 font-semibold">
                Zoom Link:
              </span>
              <input
                type="text"
                className="p-1 border"
                value={session.link ?? ""}
                onInput={(link) =>
                  setSession({
                    ...session,
                    link: link.currentTarget.value,
                  })
                }
              />
            </div>
            <div className="grid grid-cols-1">
              <span className="pt-3 pb-1 block text-gray-600 font-semibold">
                Beschreibung:
              </span>
              <div className="bg-white border px-4">
                <Editor
                  editorState={editorState}
                  onChange={(state) => {
                    setEditorState(state)
                    setSession({
                      ...session,
                      description: stateToHTML(state.getCurrentContent()),
                    })
                  }}
                />
              </div>
            </div>

            <div className="col-span-5 justify-self-end">
              <button
                className="mt-4 mt-2 mx-2 h-8 bottom-0 bg-white col-auto bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded"
                onClick={() => props.setEdit(false)}
              >
                <span className="align-middle py-1 px-4">Abbrechen</span>
              </button>
              <button
                className="mt-4 mt-2 mx-2 h-8 bottom-0 bg-white col-auto bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white border border-blue-500 hover:border-transparent rounded"
                onClick={save}
              >
                <span className="align-middle py-1 px-4">Speichern</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {saving && (
        <div className="m-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 bg-indigo-100 bg-opacity-75 border rounded-lg">
          <div className="p-10 font-bold">Wird gespeichert - Bitte warten.</div>
        </div>
      )}
    </div>
  )
}

export default memo(SessionEditor)
