import React, { memo, useEffect, useState } from "react"
import ImageCropper from "./cropper"
import { CropInfo } from "../session-editor"

const activeTab =
  "inline-block p-2 text-gray-600 bg-white rounded-t-lg active cursor-default"
const inactiveTab =
  "inline-block p-2 rounded-t-lg hover:text-gray-600 hover:bg-slate-50 cursor-pointer"

interface Props {
  defaultCropInfo?: CropInfo
  setDefaultCropInfo: (cropInfo: CropInfo) => void
  funnyCropInfo?: CropInfo
  setFunnyCropInfo: (cropInfo: CropInfo) => void
}

const missingInfo: CropInfo = {
  crop: { x: 0, y: 0 },
  pixelCrop: { x: 0, y: 0, height: 0, width: 0 },
  zoom: 1,
  image: "/img/missing.jpeg",
  rotation: 0,
}
function PictureTab({
  defaultCropInfo,
  setDefaultCropInfo,
  funnyCropInfo,
  setFunnyCropInfo,
}: Props) {
  const [tab, setTab] = useState<"default" | "funny">("default")

  return (
    <div className="h-full flex flex-col">
      <ul className="flex-none flex flex-wrap text-sm font-medium text-center text-gray-500">
        <li className="mr-2">
          <a
            onClick={() => setTab("default")}
            className={tab === "default" ? activeTab : inactiveTab}
          >
            Standard
          </a>
        </li>
        <li className="mr-2">
          <a
            onClick={() => setTab("funny")}
            className={tab === "funny" ? activeTab : inactiveTab}
          >
            Lustig
          </a>
        </li>
      </ul>
      <div className="bg-white p-2 h-full grow">
        {tab === "default" ? (
          <ImageCropper
            key="default"
            cropInfo={defaultCropInfo ?? missingInfo}
            setCropInfo={setDefaultCropInfo}
          />
        ) : (
          <ImageCropper
            key="funny"
            cropInfo={funnyCropInfo ?? missingInfo}
            setCropInfo={setFunnyCropInfo}
          />
        )}
      </div>
    </div>
  )
}

export default memo(PictureTab)
