import React from "react"

function scroll(el: HTMLDivElement) {
  el?.scrollIntoView()
}

export function Impressum() {
  return (
    <div
      className="sm:mx-0 p-2 mt-10 sm:p-12 sm:px-20 bg-gray-400 rounded shadow-lg bg-opacity-75 text-xs sm:text-sm"
      ref={scroll}
    >
      <h4 className="font-semibold">Angaben gem&auml;&szlig; &sect; 5 TMG:</h4>
      <p className="py-2">Herr Dr. Jonathan Heinen</p>
      <h6 className="font-semibold pt-2">Kontakt:</h6>
      <p className="p-1">
        Telefon: +49 (0) 241 978 30 10
        <br /> Telefax: +49 (0) 241 978 30 10
        <br /> E-Mail: heinen@marsplication.de
      </p>

      <h4 className="font-semibold pt-2">
        Verantwortlich f&uuml;r den Inhalt nach &sect; 55 Abs. 2 RStV:
      </h4>
      <p className="p-1">
        Jonathan Heinen
        <br /> Schildstraße 17
        <br /> 52062 Aachen
      </p>

      <h4 className="font-semibold pt-2">Streitschlichtung</h4>
      <p className="text-justify">
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <h4 className="font-semibold pt-2">Haftung f&uuml;r Inhalte</h4>
      <p className="text-justify">
        Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
        f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
        gespeicherte fremde Informationen zu &uuml;berwachen oder nach
        Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
        hinweisen.
      </p>

      <p className="text-justify pt-1">
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon
        unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem
        Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich.
        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
        Inhalte umgehend entfernen.
      </p>

      <h4 className="font-semibold pt-2">Haftung f&uuml;r Links</h4>
      <p className="text-justify">
        Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r
        diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r
        die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
        Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
        Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
        &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der
        Verlinkung nicht erkennbar.
      </p>

      <p className="text-justify pt-1">
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
        ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
        entfernen.
      </p>

      <h4 className="font-semibold pt-2">Urheberrecht</h4>
      <p className="text-justify">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die
        Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
        Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen
        der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht
        kommerziellen Gebrauch gestattet.
      </p>

      <p className="text-justify pt-1">
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </p>
    </div>
  )
}
