import React, { memo, useCallback, useEffect, useState } from "react"
import Cropper from "react-easy-crop"
import { CropInfo } from "../session-editor"
import { getResizedImg } from "./image_crop"

interface Props {
  cropInfo: CropInfo
  setCropInfo: (cropInfo: CropInfo) => void
}

function ImageCropper({ cropInfo, setCropInfo }: Props) {
  return (
    <div className="flex flex-col h-full">
      <div className="grow h-auto relative">
        <Cropper
          image={cropInfo.image}
          crop={cropInfo.crop}
          rotation={cropInfo.rotation}
          zoom={cropInfo.zoom}
          aspect={947 / 710}
          onCropChange={(crop) => setCropInfo({ ...cropInfo, crop })}
          onCropAreaChange={(_a, pixelCrop) =>
            setCropInfo({ ...cropInfo, pixelCrop })
          }
          onRotationChange={(rotation) =>
            setCropInfo({ ...cropInfo, rotation })
          }
          onZoomChange={(zoom) => setCropInfo({ ...cropInfo, zoom })}
        />
      </div>
      <div className="flex gap-2 justify-items-stretch">
        <div className="flex flex-col shrink grow">
          <span className="text-sm align-top">Zoom</span>
          <input
            type="range"
            value={cropInfo.zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) =>
              setCropInfo({ ...cropInfo, zoom: parseFloat(e.target.value) })
            }
          />
        </div>
        <div className="flex flex-col shrink grow">
          <span className="text-sm align-top">Rotation</span>
          <input
            type="range"
            value={(cropInfo.rotation + 180) % 360}
            min={0}
            max={359.99}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e) =>
              setCropInfo({
                ...cropInfo,
                rotation: (parseFloat(e.target.value) + 180) % 360,
              })
            }
          />
        </div>
        <input
          type="file"
          style={{ display: "none" }}
          id="inputfile"
          onChange={(e) => {
            getResizedImg(URL.createObjectURL(e.target.files![0])).then((img) =>
              setCropInfo({
                pixelCrop: { x: 0, y: 0, width: 0, height: 0 },
                crop: { x: 0, y: 0 },
                rotation: 0,
                zoom: 1,
                image: img,
              }),
            )
          }}
        />
        <button
          className="mt-2 mx-2 h-8 bottom-0 bg-white col-auto bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white border border-blue-500 hover:border-transparent rounded px-5"
          onClick={() => document.getElementById("inputfile")!.click()}
        >
          Auswählen
        </button>
      </div>
    </div>
  )
}

export default memo(ImageCropper)
