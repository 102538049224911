import React, { ReactNode } from "react"

const commonClass =
  "float-right overflow-hidden mb-2 mx-2 h-8 col-auto bg-transparent text-blue-700 font-semibold border-blue-500 rounded border"
const enabledClass =
  commonClass + " hover:bg-blue-500 hover:text-white hover:border-transparent"
const disabledClass = commonClass + " opacity-50  cursor-not-allowed"

function Button({
  enabled,
  onClick,
  children,
}: {
  enabled: boolean
  onClick: () => void
  children?: ReactNode
}) {
  return (
    <button
      className={enabled ? enabledClass : disabledClass}
      onClick={enabled ? onClick : undefined}
    >
      <img className="h-8 inline" src="zoom.jpg" alt="" />
      <span className="align-middle px-2">{children}</span>
    </button>
  )
}

export default Button
