import { Impressum } from "./impressum"
import React, { useState } from "react"

export function Footer() {
  const [impressum, setImpressum] = useState(false)
  return (
    <footer
      onClick={() => {
        impressum && setImpressum(false)
      }}
      className={"bg-gray-600 lg:rounded-t-lg px-5 pb-5"}
    >
      <div className="grid sm:grid-cols-2">
        <div
          className="text-blue-200 cursor-pointer mb-3"
          onClick={() => {
            setImpressum(!impressum)
          }}
        >
          Impresum
        </div>
        <div className="text-sm">
          <div className="text-gray-200 ">
            Flag Icons made by{" "}
            <a
              className="text-blue-200"
              href="https://www.flaticon.com/authors/freepik"
              title="Freepik"
            >
              Freepik
            </a>
          </div>
          <div className="text-gray-200 ">
            Worldmap from{" "}
            <a
              className="text-blue-200"
              href="http://simplemaps.com"
              title="Freepik"
            >
              simpleMaps{" "}
            </a>
          </div>
        </div>
      </div>

      {impressum && <Impressum />}
    </footer>
  )
}
