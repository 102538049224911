import React, { useCallback, useEffect, useMemo, useState } from "react"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import SessionEditor, { ExtendedSession } from "./session-editor"
import EditButton from "./edit-button"

async function fetchSession(
  id: string,
  setSession: (session: ExtendedSession) => void,
) {
  const result = await fetch(`/edit/${id}`, { cache: "no-cache" })
  try {
    if (result.ok) {
      const session = await result.json()
      console.log("Got session-info", session)
      setSession(session)
    }
  } catch {
    const body = await result.text()
    console.log("failed to get session-info with code " + result.status, body)
  }
}

export default function EditOverlay({
  reloadSessions,
}: {
  reloadSessions: () => void
}) {
  const [edit, setEdit] = useState(false)
  const [session, setSession] = useState<ExtendedSession | "none">("none")

  const loadSession = useCallback(
    function loadSession() {
      const match = window.location.search.match(/edit=(?<id>[a-zA-Z0-9]{20})/)
      if (!match) {
        setSession("none")
        return
      }
      console.log("Edit mode try to fetch Session info.")
      const { id } = match.groups as { id: string }
      fetchSession(id, setSession)
    },
    [setSession],
  )

  const reloadSessionsAndEditSession = useCallback(() => {
    loadSession()
    reloadSessions()
  }, [loadSession, reloadSessions])
  useEffect(loadSession, [])

  if (session === "none") {
    return null
  }

  return edit ? (
    <SessionEditor
      setEdit={setEdit}
      session={session}
      reloadSessions={reloadSessionsAndEditSession}
    />
  ) : (
    <EditButton setEdit={setEdit} />
  )
}
