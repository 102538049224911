import React, {
  EffectCallback,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from "react"
import { CountryCode } from "./projects"

interface Props {
  className: string
  selected: CountryCode | undefined
  onSelect: (c: CountryCode | undefined) => void
}

function doMap(
  ref: RefObject<HTMLObjectElement>,
  selected: CountryCode | undefined,
  onSelect: (code: CountryCode | undefined) => void,
) {
  if (ref.current) {
    const map = ref.current?.contentDocument
    if (map) {
      const countries = ["cr", "co", "bo", "ec", "gh", "pl", "mk", "il", "ps", "in"]
      countries.forEach((code) => {
        const cCode: CountryCode =
          code === "il" || code === "ps" ? "ip" : (code as CountryCode)
        const country = map.getElementById(code.toUpperCase())
        if (country) {
          country.style.fill = selected === cCode ? "#d53f8c" : "#58d2cc"
          country.addEventListener("mouseover", () => onSelect(cCode))
          country.addEventListener("mouseout", () => onSelect(undefined))
        }
      })
    }
  }
}

const useMount = (fun: EffectCallback) => useEffect(fun, [])

export function WorldMap({
  className,
  selected,
  onSelect,
}: Props): JSX.Element {
  const ref = useRef<HTMLObjectElement>(null)
  const callback = useCallback(() => {
    doMap(ref, selected, onSelect)
  }, [ref, selected, onSelect])
  useMount(() => ref.current?.addEventListener("load", callback, false))
  useEffect(callback, [selected, onSelect, ref])

  return (
    <object
      data="img/world2.svg"
      type="image/svg+xml"
      className={className}
      ref={ref}
    >
      Weltkarte
    </object>
  )
}
