import React from "react"

export type EOCode =
  | "bdkj"
  | "dpsg"
  | "kjg"
  | "arbol"
  | "bolivienbruecke"
  | "paxchristi"
  | "lichtblicke"
  | "ausbildung"

export const urls: { [code in EOCode]: string } = {
  arbol: "https://www.arbol-de-la-esperanza.de/",
  bdkj: "https://www.bdkj-aachen.de/",
  bolivienbruecke: "https://www.bolivien-bruecke.de/",
  dpsg: "http://www.dpsg-ac.de/",
  ausbildung: "https://eine-welt-erleben.de/",
  kjg: "https://www.kjg-aachen.de/",
  lichtblicke: "https://www.aktion-lichtblicke.de/",
  paxchristi: "https://pax-friedensdienste.de/",
}

export default function EO({
  code,
  className,
}: {
  code: EOCode
  className?: string
}) {
  return (
    <div
      className={className + " cursor-pointer"}
      onClick={() => window.open(urls[code], "_blank")}
    >
      <img
        src={`img/eos/${code}.png`}
        className="h-full w-full object-contain"
        alt="logo der EO"
      />
    </div>
  )
}
