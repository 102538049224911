import React from "react"

function EditButton({ setEdit }: { setEdit: (state: boolean) => void }) {
  return (
    <div
      className="z-10 grid grid-flow-col fixed bg-pink-300 bg-opacity-75 hover:bg-pink-500 right-0 top-0 m-5 rounded-full cursor-pointer"
      onClick={() => setEdit(true)}
    >
      <div className="bg-teal-400 right-0 top-0 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          className="w-8 m-4"
        >
          <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
        </svg>
      </div>
      <span className="text-4xl pl-4 p-1 pr-5"> Bearbeiten </span>
    </div>
  )
}

export default EditButton
