import React, { createContext, useCallback, useEffect, useState } from "react"
import Sessions from "./sessions"
import Projects from "./projects"
import { Footer } from "./footer"
import moment from "moment"
import ZoomModal from "./zoom-modal"
import EditOverlay from "./edit/edit-overlay"
import { Session } from "./session"

export const TimeContext = createContext(0)

declare global {
  interface Window {
    mockClock: (time: string) => void
    showClock: () => void
  }
}

function App() {
  const loadSessions = useCallback(async function loadSessions() {
    const response = await fetch("/data/41010/sessions.json", {
      cache: "no-cache",
    })
    if (response.ok) {
      const sessions = (await response.json()) as Session[]
      setSessions(sessions)
    }
    setTimeout(loadSessions, 15000)
  }, [])

  const [time, setTime] = useState(Date.now)
  const [offset, setOffset] = useState(0)
  const [zoomLink, setZoomLink] = useState<string | undefined>(undefined)
  const [showClock, setShowClock] = useState(false)

  const [sessions, setSessions] = useState<Session[]>()

  useEffect(() => {
    loadSessions()
  }, [])

  function mockClock(time: string) {
    setOffset(moment(`2024-10-10T${time}+02:00`).unix() * 1000 - Date.now())
  }

  window.mockClock = mockClock
  window.showClock = () => setShowClock(true)

  useEffect(() => {
    const ticker = setInterval(() => {
      setTime(Date.now() + offset)
    }, 1000)

    function getOffset() {
      fetch("https://worldtimeapi.org/api/timezone/Europe/Berlin.json", {
        cache: "no-cache",
      })
        .then((response) => response.json())
        .then((data) => data.unixtime)
        .then((os) => setOffset(os * 1000 - Date.now()))
    }

    getOffset()
    const offseter = setInterval(getOffset, 15 * 60 * 1000)

    return () => {
      clearInterval(ticker)
      clearInterval(offseter)
    }
  }, [])

  return (
    <>
      <EditOverlay reloadSessions={loadSessions} />
      <TimeContext.Provider value={time + offset}>
        {zoomLink && (
          <ZoomModal link={zoomLink} onClose={() => setZoomLink(undefined)} />
        )}

        <div className="lg:mx-12 2xl:container 2xl:mx-auto">
          {showClock && (
            <div className="text-blue-100 font-bold fixed bg-black p-2 -ml-10 z-50 border-blue-400 border-opacity-50 border-2 rounded-lg m-5 bg-opacity-50">
              {moment(time + offset).format("DD.MM.YYYY - HH:mm:ss")} ({offset})
            </div>
          )}
          <header className="lg:rounded-b-lg overflow-hidden relative aspect-square sm:aspect-auto md:pb-1/2 mb-10">
            <img
              className="absolute w-full h-full object-cover"
              src="img/leo.jpg"
              alt="Titelbild"
            />

            <div className="absolute right-0 bottom-0 top-0 w-full">
              <h1 className="float-right mt-1/12 sm:mt-1/12 md:mt-1/12 lg:mt-1/12 text-3xl sm:text-4xl md:text-5xl lg:text-6xl right-0 m-3 md:m-10 leading-none">
                <b>DEIN</b> SOZIALES <br /> JAHR IM AUSLAND.
              </h1>

              <div className="text-center sm:text-left absolute right-0 sm:mb-1/12 bottom-0 clear-both sm:float-right sm:w-auto">
                {/*<div className="bg-teal-500 text-gray-100 pl-5 pr-5 p-2 mx-0 mb-5 w-full">*/}
                {/*  <p className="text-xs w-full sm:text-base md:text-lg lg:text-xl xl:text-2xl font-extrabold">*/}
                {/*    Infotag 28.09.2024 - 11:00 – 14:30 Uhr*/}
                {/*  </p>*/}
                {/*  <h1 className="hidden sm:block text-xs sm:text-xs md:text-sm lg:text-base font-bold">*/}
                {/*    pinu&acute;u Jugendcafe | Buchkremerstraße 2*/}
                {/*  </h1>*/}
                {/*</div>*/}

                <a href="#program">
                  <div className="bg-pink-600 text-gray-100 pl-4 p-1 pr-8 mx-0 mb-5 w-full cursor-pointer">
                    <p className="text-xs w-full sm:text-base md:text-lg lg:text-xl xl:text-2xl font-extrabold">
                      Infotag 10.10.2024 - 19:00 – 21:30 Uhr
                    </p>
                    <h1 className="hidden sm:block text-xs sm:text-xs md:text-sm lg:text-base font-bold">
                      Online über Zoom
                    </h1>
                  </div>
                </a>
              </div>
            </div>
          </header>

          <main className="grid grid-flow-row gap-0 ">


            <div
                id="program"
                className="lg:rounded overflow-hidden mt-2 bg-gray-100 mb-4 shadow-lg"
            >
              <h1 className="w-full bg-pink-600 pb-1 pt-2 px-6 text-white font-bold text-xl tracking-wider shadow-md">
                Programm:{" "}
                <span className="whitespace-nowrap">Infotag am 10.10.2024</span>
              </h1>
              <div
                  className="px-6 md:px-16 pb-6 text-justify lg:col-gap-12 lg:col-count-2 mt-4 font-normal lg:coluyarn add -D tailwindcss-multi-columnyarn add -D tailwindcss-multi-column">
                <p>
                  Am <b>10. Oktober</b> kannst Du über Links, die auf dieser
                  Website zur Verfügung gestellt werden, unsere einzelnen
                  Organisiationen kennenlernen. Für jede Organisation gibt es
                  ein Zoom Meeting, das 45 Minuten offen ist. In dieser Zeit
                  berichten Organisationen und ehemalige Freiwillige von ihren
                  Projekten und Erlebnissen, Du hast die Chance Deine Fragen zu
                  stellen und Kontakte zu knüpfen. Unser Programm wird von{" "}
                  <b>19:00 Uhr bis 21:30 Uhr</b> dauern.
                </p>
                <p className="pt-2">
                  Das <b>Beitreten</b> zu einem Vortrag ist jeweils in den{" "}
                  angegebenen Zeiten möglich. Parallel zu den einzelnen Sessions
                  mit den Organisationen gibt es einen weiteren Raum, der
                  durchgehend geöffnet ist und wo wir Dir oder Deinens Eltern
                  gerne allgemeine Fragen zum SDFV, weltwärts, zur Finanzierung
                  oder den einzelnen Projekten beantworten.
                </p>
                <p className="pt-2">
                  <b>Wichtig:</b> Die einzelnen Sessions sowie den Raum für
                  allgemeine Fragen werden wir mit <b>zoom</b> eröffnen. Hierzu
                  ist <b>keine Anmeldung</b> für Dich nötig. Du brauchst
                  lediglich den Link zum richtigen „Raum“, den wir Dir auf
                  dieser Website zur Verfügung stellen. Falls Du noch nicht mit
                  zoom gearbeitet hast, kannst Du Dich gerne erstmal ein
                  bisschen früher zum allgemeinen Raum zuschalten, um zu testen,
                  ob alles funktioniert.
                </p>
                <p className="font-bold pt-4">Wir freuen uns auf Dich!</p>
              </div>
              {!!sessions && (
                  <Sessions setZoomLink={setZoomLink} sessions={sessions ?? []}/>
              )}
            </div>


            <div className="lg:rounded overflow-hidden mt-2 bg-gray-100 mb-4 shadow-lg">
              <h1 className="w-full bg-pink-600 pb-1 pt-2 pl-6 text-white font-bold text-xl tracking-wider shadow-md">
                SDFV Infotag
              </h1>
              <div className="px-6 md:px-16 pb-6 text-justify mt-4 font-normal lg:col-count-2 col-gap-12">
                <p>
                  Der{" "}
                  <a
                      className="text-teal-500 font-bold"
                      href="https://www.fsd-aachen.de/ausland"
                  >
                    Trägerkreis des Sozialen Dienstes für Frieden und Versöhnung
                  </a>{" "}
                  ist ein Zusammenschluss von acht kleinen Organisationen, die
                  zusammen jährlich etwa 20 weltwärts-Freiwillige ins Ausland
                  entsenden.
                </p>
                <video
                    src="img/infotag.m4v"
                    poster="img/standbild.jpg"
                    className="my-5 mx-auto rounded-md overflow-hidden"
                    autoPlay
                    playsInline
                    muted
                    loop
                />

                <p>
                  Jedes Jahr im September veranstalten wir einen Infotag im
                  Zentrum von Aachen. Hier hast Du die Chance die einzelnen
                  Organisationen und Einsatzstellen kennenzulernen und auch mit
                  ehemaligen Freiwilligen in Kontakt zu kommen. Nutze den Tag
                  und finde heraus, welches das richtige Projekt für Dich ist.
                  Der Infotag in Aachen findet am{" "}
                  <strong> 28. September von 11:00 bis 14:30 im <span
                      className="whitespace-nowrap">pinu´u</span> Jugendcafe </strong>{" "}
                  (Buchkremerstraße 2) statt.
                </p>
                <p className="pt-2">
                  Am <strong> 10. Oktober </strong> veranstalten wir{" "}
                  <strong> online </strong> einen Infotag. Das bedeutet für
                  Dich, dass Du von überall aus teilnehmen kannst!
                </p>
                <p>
                  Ehemalige Freiwillige aus den verschiedenen Organisationen
                  werden von ihren Erlebnissen berichten und Eure Fragen
                  beantworten. Es werden immer mehrere Projekte parallel
                  vorgestell. Weiter unten findet ihr unser{" "}
                  <a className="text-teal-500 font-bold" href="#program">
                    Programm
                  </a>{" "}
                  für den Tag aufgelistet, sowie weitere Details zum Ablauf.
                  Darüber hinaus habt ihr die Möglichkeit, in einem Raum, der
                  während der ganzen Zeit geöffnet sein wird, Fragen jeglicher
                  Art zum Thema Freiwilligendienst zu stellen.
                </p>
              </div>
            </div>

            <div className="lg:rounded overflow-hidden mt-2 bg-gray-100 mb-4 shadow-lg">
              <h1 className="w-full bg-pink-600 pb-1 pt-2 pl-6 text-white font-bold text-xl tracking-wider shadow-md">
                Einsatzstellen
              </h1>
              <div className="px-6 md:px-16 pb-6 text-justify mt-4 font-normal">
                <p>
                  Auf der folgenden Karte kannst Du sehen, in welchen Ländern
                  sich unsere Einsatzstellen befinden. Wenn Du nähere
                  Informationen zu den einzelnen Einsatzstellen wünschst, kannst
                  Du mit einem Klick auf das Logo die Homepage der jeweiligen
                  Entsendeorganisation erreichen.
                </p>
                <p className="pt-2">
                  Weiter Informationen findest Du auf unsere Homepage{" "}
                  <a
                      className="text-teal-500 font-bold"
                      href="https://www.fsd-aachen.de/ausland"
                  >
                    www.fsd-aachen.de/ausland
                  </a>
                  . Außerdem kannst Du Dich auch mit allen Fragen direkt an uns
                  wenden:{" "}
                  <a
                      className="text-teal-500 font-bold"
                      href="mailto:Uta.Hillermann@fsd-bistum-aachen.de"
                  >
                    Uta.Hillermann@fsd-bistum-aachen.de
                  </a>
                </p>
              </div>
              <div className="px-2">
                <Projects/>
              </div>
            </div>
          </main>
          <Footer/>
        </div>
      </TimeContext.Provider>
    </>
  )
}

export default App
