import React from "react"
import { browserName } from "react-device-detect"

function ZoomModal({ link, onClose }: { link: string; onClose: () => void }) {
  function openInBrowser() {
    const browserLink = link.replace("/j/", "/wc/join/")
    window.open(browserLink, "_blank")
  }

  function openInZoom() {
    window.open(link, "_blank")
  }

  function browserImage() {
    switch (browserName) {
      case "Chrome":
        return "img/chrome.png"
      case "Firefox":
        return "img/firefox.png"
      case "Edge":
        return "img/edge.png"
      default:
        return "img/all.png"
    }
  }

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto cursor-pointer"
      onClick={onClose}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className="cursor-auto inline-block border border-gray-600 shadow-lg align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 bg-gray-300 border-b border-gray-400">
            <img className="h-10 rounded-lg inline-block" src="zoom.jpg" />
            <div className="mt-3 text-center sm:mt-0 ml-4 sm:text-left inline-block">
              <h3
                className="text-xl leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                An Zoom Meeting teilnehmen
              </h3>
              <div className="mt-2">
                <p className="text-sm leading-5 text-gray-500"></p>
              </div>
            </div>
          </div>

          <div className="mx-12 my-5">
            <p>
              Du kannst entweder die Zoom Software nutzen oder direkt im Browser
              an dem Zoom Meeting teilnehmen. Falls Du nach einem Kenncode
              gefragt wirst nutze bitte den folgenden:
            </p>
            <div className="text-center mx-auto block w-64 p-2 my-5 mx-auto bg-gray-200 border-gray-500 border-2 border-dashed">
              Kenncode: <span className="font-mono pl-5">infotag</span>
            </div>
          </div>

          <div className="flex justify-around mx-auto">
            <div
              className="w-40 m-5 border-2 border-indigo-300 rounded-l p-5 shadow-lg bg-gray-200 hover:bg-gray-300 hover:shadow-md cursor-pointer"
              onClick={openInBrowser}
            >
              <img
                className="h-16 w-16 mx-auto rounded-lg"
                src={browserImage()}
              />
              <h1 className="w-full text-center pt-5 font-semibold">
                Im Browser <br /> teilnehmen
              </h1>
            </div>
            <div
              className="w-40 m-5 border-2 border-indigo-300 rounded-l p-5 shadow-lg bg-gray-200 hover:bg-gray-300 hover:shadow-md cursor-pointer"
              onClick={openInZoom}
            >
              <img className="h-16 w-16 mx-auto rounded-lg" src="zoom.jpg" />
              <h1 className="w-full text-center pt-5 font-semibold">
                Mit Zoom <br /> teilnehmen
              </h1>
            </div>
          </div>

          <div className="my-5 sm:w-full bg-gray-50 px-4 py-3 inline-block">
            <button
              type="button"
              className="block mx-auto rounded-md border border-transparent px-16 py-2 bg-red-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={onClose}
            >
              Zurück
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ZoomModal
